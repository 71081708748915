.jbm-thumb-social {
    padding-bottom: 10px;
    border-bottom: 1px solid $colorFaded;
    margin-bottom: 10px;
    span {
        color: $colorFaded;
        font-size: 14px;
        text-align: left;
        a {
            color: $colorPrimary;
            font-size: 14px;
        }
    }
    img {
        padding-right: 20px;
    }
    ul {
        list-style: none;
        padding-top: 0px;
        right: 0;
        float: right;
        li {
            float: left;
            a {
                color: $colorFaded;
                font-size: 14px;
                padding-right: 8px;
                i {
                    color: $colorInverse;
                    &:hover,
                    &:focus {
                        color: $colorPrimary;
                    }
                }
            }
        }
    }
}

/**
   * 3. LOGO & NAVIGATION
   * -----------------------------------------------------------------------------
   */

.jbm-pg-hd h3 {
    color: $colorDark;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    margin: 0;
}

.search-bar {
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100%;
    display: none;
    z-index: 9;
}

.search-bar .form-inline {
    background-color: $colorLight;
}

.jbm-search-sm-btn {
    border: 0px;
    padding: 0px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: $colorLight;
}

.m-r-4 {
    margin-right: -4px;
}

#header-search-input {
    min-width: 300px;
    padding: 0px 15px;
}

.jbm-menu-icon {
    margin-top: 48px;
}

.jbm-menu-icon svg {
    width: 40px;
}

.jbm-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(63, 79, 94, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    z-index: 9;
}

.jbm-mobile-bottom-wrap-inner {
    border-top: 1px solid $colorFaded;
    padding-top: 30px;
    padding-bottom: 30px;
}

.mobile-user-image img {
    border-radius: 50%;
}

.mob-logout {
    font-weight: bold;
    margin-bottom: 10px;
    display: inline-block;
}

.mobile-username a {
    font-size: 14px;
    color: $colorInverse;
}

.mobm-active .jbm-menu-wrap {
    left: 0;
    opacity: 1;
    visibility: visible;
}

.mobm-active .jbm-overlay {
    opacity: 1;
    visibility: visible;
}

.jbm-menu-container > ul > li:nth-child(6) > ul {
    right: 0;
}

/* Handle */

.white span {
    color: $colorLight;
}

.white {
    color: $colorLight !important;
}

/* Display */
.display-block {
    display: block;
}

.dis-inline li {
    display: inline-block;
}

.list-none {
    margin: 0;
    padding: 0;
    list-style: none;
}

.plan-list li {
    margin-bottom: 40px;
    color: #454545;
    font-size: 15px;
    font-weight: 400;
}

.jbm-dis-none {
    display: none;
}

/* Positions */
.pos-relative {
    position: relative;
}

/* Buttons */

.jbm-callout-in .ktm-btn:focus,
.jbm-callout-in .ktm-btn:hover {
    background-color: $colorLight;
    color: #49565f;
    text-decoration: none;
}

.ktm-btn.wd-increase {
    min-width: 230px;
}

.link-primary {
    font-size: 14px;
    font-weight: 600;
    &:hover,
    &:focus {
        color: $colorPrimary;
        text-decoration: none;
    }
}

/**
    * 4. SECTION
    * -----------------------------------------------------------------------------
    */

/* Section Cateogry */
.jbm-category-box {
    border: 1px solid #e2e2e2;
    text-align: left;
    // padding: 20px;
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;

    // height: 140px;
    &:before {
        content: "";
        position: absolute;
        top: -6px;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: transparent;
        transition: all 0.2s ease;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
    }

    a.jbm-cat-title {
        color: #454545;
        font-size: 16px;
        font-weight: 600;
        line-height: 45px;
        display: inline-block;
        text-align: left;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .jbm-cat-jobs {
        font-size: 12px;
        color: $colorFaded;
    }

    &:hover {
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.23);
        -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.23);
        -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.23);

        &:before {
            top: 0px;
        }

        .category-icon svg path {
            fill: $colorPrimary;
        }
    }
}

.category-icon {
    float: left;

    svg {
        width: 45px;
        height: 45px;

        path {
            fill: #454545;
        }
    }
}

.jbm-cat-jobs {
    float: right;
    position: relative;
    top: 5px;
}

/* Load More */
.jbm-loadmore {
    color: $colorPrimary;
    font-size: 16px;
    font-weight: 800;
}

/* Call Out */

.callout-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.jbm-callout-in {
    max-width: 600px;
    // height: 550px;
    position: relative;
    z-index: 1;
}

.jbm-company-logo {
    float: left;
    width: 75px;
    height: 75px;
    line-height: 75px;
    margin-right: 30px;
    text-align: center;
    background-color: $colorPrimary;
    color: $colorLight;
    font-size: 24px;
    font-family: "Raleway", sans-serif;
    font-weight: 900;
}

.jbm-job-locaction a,
.jbm-job-price span,
.jbm-job-timing span {
    font-size: 13px;
    color: #454545;
}

.jbm-job-detail {
    display: inline-block;
}

.jbm-job-detail a {
    font-weight: 800;
    color: #b6b6b6;
    font-size: 14px;
}

.jbm-job-apply {
    float: right;
}

.jbm-job-apply a {
    font-weight: 800;
    font-size: 14px;
}

.jbm-job-links {
    margin-top: 21px;
}

.jbm-job-detail a:hover,
.jbm-job-apply a:hover,
.jbm-job-apply a:focus {
    color: #454545;
}

/*  Counter Up */
.main-2nd-bg {
    background-color: $colorSecondary;
}

.counterup-box {
    margin-bottom: 30px;
    border: 1px dashed $colorFaded;
    padding: 35px;
    text-align: center;
    color: $colorLight;
}

.counterup-box h2 {
    margin-top: 0px;
}

.counterup-box p {
    font-size: 16px;
    margin-bottom: 0px;
}

/* Candidate Box */
.jbm-section-candidate {
    h2 {
        font-size: 32px;
        margin-top: 5px;
        margin-bottom: 40px;
    }

    .candidate-box {
        //  background-image: url(../images/bg-testimonial.jpg);
        background-size: cover;
        height: 430px;
    }
}

.candidate-preview {
    max-width: 470px;
    display: inline-block;
    vertical-align: top;
    width: 100%;
}

.candidate-thumb {
    max-width: 70px;
    display: inline-block;
    vertical-align: top;
    max-height: 430px;
    overflow: hidden;
}

.slick-vertical .slick-slide {
    margin-bottom: 19px;
}

.candidate-bottom {
    border-top: 1px solid #e3e3e3;
}

.slider-nav-list {
    position: relative;
    cursor: pointer;
}

.slider-nav-list:focus {
    outline: 0;
    border: 0px;
}

.candi-thumb-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $colorPrimary;
    opacity: 0;
    visibility: hidden;
}

.slider-nav-list:hover .candi-thumb-overlay,
.slider-nav-list.slick-current .candi-thumb-overlay {
    opacity: 1;
    visibility: visible;
}

.candi-thumb-overlay svg {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.candidate-bottom .ktm-btn-2 {
    margin-right: 20px;
    border-color: #b6b6b6;
    color: #b6b6b6;
}

.candidate-bottom .ktm-btn-2:hover,
.candidate-bottom .ktm-btn-2:focus {
    background-color: #b6b6b6;
    color: $colorLight;
}

.candidate-bottom .jbm-social-icon-1 {
    margin-top: 15px;
}

/* Home Blog */
.darkbg {
    background-color: #e9e9e9;
}

.blog-posts-in {
    background-color: $colorLight;
    overflow: hidden;
    border-radius: 0px;
    margin-bottom: 30px;

    &:hover {
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.4);
    }

    a {
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    h5 {
        margin-bottom: 0px;
        margin-top: 8px;

        a {
            color: #454545;
        }
    }

    p {
        color: #454545;
        line-height: 26px;
        margin-bottom: 0px;
        margin-top: 35px;
        font-size: 14px;
    }

    .blog-grid-meta {
        span {
            color: $colorFaded;
        }

        a {
            color: #3992dd;
        }
    }
    .blog-post-thumb {
        position: relative;

        img {
            float: left;
            width: 100%;
            height: 100%;
        }
    }

    .blog-post-desc {
        padding: 10px;
        position: relative;

        h5 {
            a {
                color: $colorPrimary;
            }
        }
    }
}

.blog-grid-meta {
    margin-top: 20px;
}

.blog-redmore {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    font-size: 25px;
    color: $colorInverse;
    display: inline-block;
    position: absolute;
    top: -25px;
    right: 30px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.23);
    &:hover,
    &:focus {
        background-color: $colorInverse;
        color: $colorLight;
    }
}

.sectiob-blog .blog-post-desc {
    float: left;
    width: 100%;
}

/* Blog Standard Grid */
.jbm-blog-title a:hover,
.jbm-blog-title a:focus {
    text-decoration: none;
}

.blog-grid-navigation {
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    padding-top: 20px;
    padding-bottom: 20px;
}

.blog-grid-navigation p {
    color: #b6b6b6;
}

/* Comments */
.jbm-comments-loop {
    border: 1px solid #e3e3e3;
    padding: 25px;
    position: relative;
    overflow: visible;
    margin-top: 45px;
}

.jbm-comments-loop h5 {
    margin-top: 0px;
    color: #454545;
    display: inline-block;
}

.jbm-comments-loop span {
    font-size: 12px;
    color: #b6b6b6;
}

.jbm-comments-loop p {
    color: #454545;
    line-height: 30px;
    margin-bottom: 0px;
}

.jbm-reply-btn {
    position: absolute;
    bottom: -10px;
    right: 20px;
    text-align: center;
    color: #454545;
    font-size: 12px;
    background-color: #d4d4d4;
    padding: 4px 19px;
}

.jbm-reply-btn:hover,
.jbm-reply-btn:focus {
    color: $colorLight;
}

.jbm-comments-loop.sub-comment {
    margin-left: 120px;
}

.change-pass .ktm-btn {
    padding: 0 26px;
    font-size: 14px;
    font-weight: 400;
    min-width: 200px;
}

.border-0 {
    border: 0px !important;
    padding: 0px !important;
}

/* Help Box */
.jbm-section-helpbox a {
    color: $colorLight;
    text-decoration: underline;
}

/* job info */
.job-info {
    padding-bottom: 100px;
}

.job-info .media-body {
    position: relative;
    top: 13px;
    left: 30px;
}

.job-info .fa-download {
    margin-top: 8px;
    margin-top: 8px;
    color: #b6b6b6;
}

.job-info #cover {
    color: #b6b6b6;
    text-align: center;
    display: block;
}

.job-info-download-links {
    max-width: 250px;
    display: inline-block;
}

.job-info-download-links i {
    font-size: 20px;
    margin-bottom: 20px;
}

.job-info-download-links span {
    width: 100%;
    display: block;
    color: #b6b6b6;
}

.job-info-download-links:hover,
.job-info-download-links:focus {
    text-decoration: none;
}

.job-info-download-links:hover i,
.job-info-download-links:focus i,
.job-info-download-links:hover span,
.job-info .candidate-bottom.padding-top-30 {
    border: 0;
    padding-top: 0;
}

/* Candidate personal info */
.candidate-personal-info.jbm-accordion {
    border: 3px solid #e4e4e4;
    border-radius: 2px;
    padding: 30px 35px 0;
    display: block;
    box-shadow: none;
}

.candidate-personal-info a {
    color: #b6b6b6;
    display: block;
}

.candidate-personal-info a i {
    font-size: 18px;
    margin-right: 8px;
}

.candidate-personal-info a:hover,
.candidate-personal-info a:focus {
    text-decoration: none;
}

a.expand-candi-info {
    width: 50px;
    height: 50px;
    z-index: 1;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    bottom: -20px;
    font-size: 12px;
    color: $colorLight;
    text-align: center;
    line-height: 50px;
    display: inline-block;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.51);
    background-color: #86af49;
}

a.expand-candi-info i {
    font-size: 12px;
    margin-right: 0px;
    line-height: inherit;
}

a.expand-candi-info:hover i,
a.expand-candi-info:focus i {
    color: $colorLight;
}

.jbm-job-content {
    margin-top: 80px;
}

.jbm-job-content h5 {
    font-weight: 600;
    margin-bottom: 40px;
}

.jbm-job-content p {
    color: #626262;
    margin-bottom: 40px;
}

.jbm-job-content ul {
    padding-left: 15px;
    margin-bottom: 40px;
}

.jbm-job-content ul li {
    line-height: 34px;
    color: #626262;
    padding-left: 0px;
    font-size: 14px;
}

.jbm-banner img {
    max-width: 100%;
}

.tag {
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    padding-top: 19px;
    padding-bottom: 19px;
}

.tag-inner-cont,
.tag-inner-cont a {
    font-size: 12px;
    color: #b6b6b6;
}

.tag .fa-tags {
    color: #b6b6b6;
    font-size: 12px;
}

.jbm-social-color-2 li a {
    font-size: 14px;
}

/* best candidates */
.best-candidate-box {
    padding-bottom: 20px;
    position: relative;
}

.best-candidate-box img {
    width: 100%;
}

.best-candidate-box h5 {
    margin-top: 20px;
}

.best-candidate-box h5 a {
    font-weight: 600;
    color: #454545;
}

.best-candi-posi {
    font-size: 12px;
    color: #3992dd;
}

.best-candidate-box a:hover,
.best-candidate-box a:focus {
    text-decoration: none;
}

.best-candi-time {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    color: $colorLight;
    padding: 0px 16px;
    line-height: 28px;
    height: 25px;
    font-size: 12px;
    font-weight: 100;
}

.controls {
    float: right;
    position: relative;
    top: 36px;
}

.row.best-candidate-img {
    margin-bottom: 70px;
}

a.info-open-button {
    clear: both;
    width: 45px;
    height: 45px;
    border-radius: 33px;
    position: absolute;
    right: 62px;
    top: 200%;
}

.info-open-button .fa-plus {
    color: $colorLight;
    position: relative;
    top: 16px;
    left: 17px;
}

/* 404 error */
.error h1 {
    font-size: 200px;
    font-weight: 700;
}

.error .fa-lightbulb-o {
    position: relative;
    top: 13px;
    font-size: 160px;
    padding-right: 15px;
    padding-left: 15px;
}

.error span {
    font-size: 50px;
    display: block;
}

a.back-to-home {
    border: 2px solid;
    border-radius: 55px;
    font-weight: 600;
    text-decoration: none;
    font-size: 16px;
    padding: 10px 40px;
}

.error h4 {
    line-height: 34px;
    font-weight: 700;
    font-size: 20px;
}
a.see-plans {
    border: 2px solid;
    border-radius: 55px;
    font-weight: 600;
    text-decoration: none;
    font-size: 16px;
    position: relative;
    top: 50px;
    display: inline-block;
    padding: 10px 65px;
}

/* Services */
.our-services h6,
.process h6,
.best-partner h6 {
    font-family: "Libre Franklin", sans-serif;
    font-weight: 700;
    color: #626262;
}

.our-services .fa {
    font-size: 30px;
    padding-right: 30px;
}

.our-services .media-heading {
    text-transform: uppercase;
    margin-bottom: 35px;
}

.our-services .media {
    margin-bottom: 60px;
}

/* Team */
.team {
    background: #e9e9e9;
}

blockquote p {
    font-size: 28px;
    line-height: 40px;
    margin-left: 10px;
}

.team-member {
    background: $colorLight;
    border: 5px solid $colorLight;
    margin-bottom: 30px;
}

.team-member .member-thumb {
    width: 100%;
}

.team-member ul {
    list-style: none;
    overflow: hidden;
    width: 65%;
    margin: auto;
}

.team-member ul li {
    float: left;
    padding-right: 12px;
}

.team-member ul li a {
    color: #b6b6b6;
    font-size: 14px;
}

/* Accordion */
.jbm-accordion {
    border: 1px solid #e4e4e4;
    border-radius: 2px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    position: relative;
}

.jbm-accordion-header {
    padding: 18px 35px 18px 35px;
}

.jbm-accordion-more {
    padding: 0px 35px 35px 35px;
}

.candidate-personal-info .jbm-accordion-more {
    padding: 0px;
}

.jbm-accordion-more p {
    margin-bottom: 35px;
}

.jbm-accordion-more p:last-child {
    margin-bottom: 0px;
}

/* Action Buttons */
.jbm-notification span {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    background-color: $colorFaded;
    font-size: 16px;
    display: inline-block;
}

.jbm-notification p {
    display: inline-block;
    margin-left: 15px;
    margin-bottom: 0px;
    font-size: 14px !important;
}

.jbm-normal span,
.jbm-normal p {
    color: $colorInverse;
}

.jbm-fail span,
.jbm-fail p {
    color: $colorDanger !important;
}

/* Pricing Plan Box */
.jbm-plan-outer {
    background: #e7e7e7;
    padding-bottom: 30px;
}

.jbm-plan-inner {
    background: $colorLight;
    margin: 0 auto;
    position: relative;
    bottom: 40px;
    border: 1px solid #ccc;
    width: 270px;
    padding-top: 40px;
}

.jbm-plan-inner.text-center .fa {
    font-size: 34px;
}

.jbm-plan-inner h3 {
    display: block;
    font-size: 30px;
    padding-top: 30px;
    font-family: "Dancing Script", cursive;
    font-weight: 100;
}

.jbm-plan-inner strong {
    display: block;
    font-size: 60px;
    margin-bottom: 40px;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
}

.jbm-plan-inner strong span {
    font-size: 20px;
}

.jbm-plan-signup {
    font-size: 16px;
}

.jbm-plan-outer:hover .jbm-plan-signup,
.plan-hover .jbm-plan-signup {
    color: $colorLight;
    text-decoration: none;
}

.jbm-blog-list img {
    padding-right: 30px;
}

.jbm-blog-list .ktm-btn {
    min-width: 130px;
    font-size: 12px;
    font-weight: 600;
}

.media h5 a {
    color: #23527c;
}

.media h5 a:hover {
    text-decoration: none;
}

.jbm-blog-list .media-left {
    float: left;
}

/* Blog Grid */

.jbm-blog-grid h5 a {
    color: #23527c;
}

.jbm-blog-grid > img {
    width: 100%;
}

.jbm-blog-grid h5 a:hover {
    text-decoration: none;
}

.jbm-blog-grid .ktm-btn {
    min-width: 130px;
    font-size: 12px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
}

/* standard layout */
.jbm-standard-layout-in h3 a {
    color: #23527c;
}

.jbm-standard-layout-in h3 a:hover {
    text-decoration: none;
}

/* candidate login */
h1 {
    font-family: Tahoma, Arial, sans-serif;
}

.box {
    width: 40%;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.2);
    padding: 35px;
    border: 2px solid $colorLight;
    border-radius: 20px/50px;
    background-clip: padding-box;
    text-align: center;
}

.button {
    font-size: 1em;
    padding: 10px;
    color: $colorLight;
    border: 2px solid #06d85f;
    border-radius: 20px/50px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
}

.button:hover {
    background: #06d85f;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
}

.overlay:target {
    visibility: visible;
    opacity: 1;
}

.g-recaptcha {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.popup {
    background: $colorLight;
    border-radius: 5px;
    width: 870px;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 99;
    transition: all 5s ease-in-out;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    padding-left: 15px;
    padding-right: 15px;
}

.close-btn {
    position: absolute;
    top: -20px;
    right: -15px;
    z-index: 99;
    cursor: pointer;
}

.close-btn i {
    font-size: 13px;
    color: #40505f;
    background-color: $colorLight;
    width: 35px;
    height: 35px;
    line-height: 30px;
    text-align: center;
    border: 2px solid #40505f;
    border-radius: 100px;
}

.popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
}

.popup .close:hover {
    color: #06d85f;
}

.popup .content {
    max-height: 30%;
    overflow: auto;
}

// .jbm-login-side {
//     padding-top: 55px;
//     background-image: url(../img/popup-bg.jpg);
//     position: relative;
//     background-size: cover;
// }

.jbm-login-side:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.7;
}

.jbm-login-side ul,
.jbm-login-side h3,
.jbm-login-side span,
.jbm-login-side i {
    position: relative;
    z-index: 9;
}

.jbm-login-side .fa {
    font-size: 80px;
    color: #34500a;
    text-align: center;
    display: block;
}

.jbm-login-side i.fa.fa-lightbulb-o.margin-bottom-50 {
    color: $colorLight;
}

.jbm-login-side .fa:hover {
    color: $colorLight;
}

.jbm-login-side ul {
    list-style: none;
    padding: 0;
    margin-bottom: 251px;
}

.jbm-social-icons-2 {
    float: left;
    width: 100%;
    padding-bottom: 28px;
}

.jbm-login-side ul li a {
    color: $colorLight;
    font-size: 16px;
    text-align: center;
    display: block;
    line-height: 30px;
}

.jbm-login-side h3 {
    color: $colorLight;
    text-align: center;
    font-weight: 700;
}

.jbm-social-icons {
    float: left;
    width: 100%;
    text-align: center;
    position: static !important;
}

.jbm-social-icons li {
    display: inline-block;
}

.jbm-social-icons ul {
    list-style: none;
}

.jbm-social-icons .fa {
    font-size: 14px;
    float: left;
    margin-right: 12px;
}

.jbm-field .col-md-12 {
    padding: 0;
}

.jbm-social-icons {
    position: relative;
    left: 30%;
    padding-bottom: 30px !important;
    margin-bottom: 0 !important;
}

li.candidate {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

li.employer {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

ul.jbm-login-form.padding-top-40 {
    position: relative;
    left: 13%;
}

.jbm-field {
    clear: both;
}

:-moz-placeholder {
    color: $colorFaded;
    opacity: 1;
}

::-moz-placeholder {
    color: $colorFaded;
    opacity: 1;
}

:-ms-input-placeholder {
    color: $colorFaded;
}

::-ms-input-placeholder {
    color: $colorFaded;
}

.jbm-field textarea[type="text"] {
    margin-top: 30px;
    margin-bottom: 30px;
    height: 60px;
}

.jbm-field .forgot {
    text-align: right;
    display: block;
    font-size: 12px;
}

.jbm-social-icons-2 ul li {
    border: 0;
    margin-right: 10px;
    cursor: pointer;
    padding: 15px 10px;
}

.jbm-social-icons-2 ul li a {
    color: $colorLight;
    font-size: 20px;
}

.jbm-social-icons-2.style3 {
    float: left;
    width: 100%;
    text-align: center;
}

.jbm-field.login-style input:first-of-type {
    margin-bottom: 20px;
}

.jbm-or {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: -8px;
}

.jbm-or span {
    color: #b6b6b6;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
}

.jbm-social-icons-2.style3 ul li {
    padding: 0;
    border-radius: 7px;
    float: none;
    display: inline-block;
}

.jbm-social-icons-2.style3 ul li a {
    padding: 0;
}

.jbm-social-icons-2.style3 ul li i {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.jbm-social-icons-2 .facebook {
    background: #3b5998;
}

.jbm-social-icons-2 .google-plus {
    background: #df4a32;
}

.jbm-social-icons-2 .twitter {
    background: #00b6f1;
}

.jbm-social-icons-2 .linkedin {
    background: #007bb6;
}

.col-md-5.or {
    border-bottom: 1px solid #c4c4c4;
}

.col-md-5.or span {
    line-height: 0;
}

a:hover {
    text-decoration: none;
}

/* Step Process */
.jbm-step-process {
    float: left;
    width: 100%;
    background: $colorLight;
}

.jbm-hd {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
}

.jbm-hd h2 {
    font-size: 32px;
    font-weight: 900;
    padding-bottom: 20px;
    position: relative;
    padding-top: 20px;
}

.jbm-hd h2:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 70px;
    height: 3px;
    transform: translateX(-50%);
}

.jbm-hd h2 span {
    font-size: 32px;
    font-weight: 400;
}

.jbm-hd p {
    color: #626262;
    font-size: 16px;
    font-weight: 400;
}

.jbm-process {
    float: left;
    width: 100%;
    display: table;
    padding: 70px 0 0 50px;
    margin-bottom: 20px;
}

.jbm-process i {
    font-size: 26px;
    width: 75px;
    height: 75px;
    border-radius: 100%;
    border: 4px solid;
    text-align: center;
    line-height: 75px;
    background-color: $colorLight;
}

.jbm-process-info {
    float: left;
    width: 80%;
    display: table-cell;
}

.jbm-process.fst .jbm-process-info {
    padding: 8px 53px 0 25px;
}

.jbm-process-info h3 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 20px;
}

.jbm-process-info p {
    color: #454545;
    font-size: 14px;
    line-height: 26px;
}

.jbm-stp-prcs {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 50px;
}

.jbm-stp-prcs:before {
    content: "";
    position: absolute;
    top: 25px;
    left: 50%;
    width: 4px;
    height: 100%;
    border-left: 2px dashed;
}

.jbm-process.scnd {
    text-align: right;
    padding-right: 50px;
}

.jbm-process.scnd .jbm-process-info {
    padding: 8px 0 0 50px;
}

.get-started {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 70px;
}

.jbm-section-helpbox {
    float: left;
    width: 100%;
    background-color: #86af49;
}

.jbm-process.mg-btm-none {
    margin-bottom: 0;
}

.get-started a {
    font-size: 14px;
    font-weight: 700;
    border: 2px solid;
    padding: 10px 50px;
    border-radius: 40px;
}

.dottedd {
    width: 15px;
    height: 15px;
    border-radius: 100px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.dottedd.btm {
    bottom: -50px;
}

/* Personal Details */
.jbm-personal-details {
    float: left;
    width: 100%;
}

.jbm-pm-details {
    float: left;
    width: 100%;
    border: 2px solid $colorFaded;
    padding: 35px 30px 40px;
}

.jbm-pm-details h3 {
    color: #454545;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
    padding: 0;
    margin-bottom: 40px;
}

.jbm-pm-details form {
    float: left;
    width: 100%;
}

.jbm-pm-details form input {
    width: 100%;
    background-color: $colorLight;
    color: $colorFaded;
    font-size: 14px;
    font-weight: 400;
    height: 60px;
    border: none;
    border-bottom: 1px solid #d4d4d4;
    outline: none;
    margin-bottom: 40px;
}

.mg-btm-none {
    margin-bottom: 0 !important;
}

/* Search Filter with Alphabets */
.jbm-select-by-alphabets {
    float: left;
    width: 100%;
    margin: -80px 0 50px;
    position: relative;
    z-index: 9;
}

.jbm-alphabets {
    float: left;
    width: 100%;
    background-color: $colorLight;
    padding: 40px 40px 40px 50px;
    border-top: 4px solid;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
}

.jbm-alphabets ul {
    margin-bottom: 35px;
    padding: 0;
}

.jbm-alphabets ul li {
    display: inline-block;
    margin-right: 6px;
}

.jbm-alphabets ul li:last-child {
    margin-right: 0;
}

.jbm-alphabets ul li a {
    color: #8f9ba7;
    font-size: 13px;
    font-weight: 400;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #a7b2bd;
    text-align: center;
    display: inline-block;
}

.jbm-alphabets ul li a.selected,
.jbm-alphabets ul li a:hover,
.jbm-alphabets ul li a:focus {
    color: $colorLight;
    background-color: #86af49;
    border-color: #86af49;
}

.jbm-selective {
    float: left;
    width: 100%;
}

.jbm-selective ul {
    padding: 0;
    margin: 0;
    float: left;
}

.jbm-selective ul li {
    display: inline-block;
    background-color: #e5e5e5;
    padding: 8px 20px;
    border-radius: 20px;
}

.jbm-selective ul li span {
    color: #737373;
    font-size: 13px;
}

.jbm-selective ul li i {
    color: #737373;
    font-size: 12px;
    cursor: pointer;
    padding-left: 5px;
}

.clearall {
    float: right;
    font-size: 13px;
    font-weight: 400;
    background: none;
    border: none;
    padding-top: 10px;
}

.media-heading.style2 {
    font-weight: 700;
    font-size: 30px;
    position: relative;
    left: 20px;
    color: $colorPrimary;
}

.media-body.style2 {
    left: 0;
}

/* Resume Page */
.jbm-resume-form input {
    width: 100%;
    margin-bottom: 45px;
    border: 0;
    border-bottom: 1px solid $colorFaded;
    padding-bottom: 15px;
    padding-top: 15px;
    font-size: 14px;
}

.check-mark i {
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    background-color: $colorFaded;
    color: $colorInverse;
    font-size: 12px;
    border-radius: 100px;
    cursor: pointer;
}

.jbm-notification.jbm-normal a {
    font-size: 14px;
    font-weight: 700;
}

.jbm-notification.jbm-normal a i {
    padding-left: 10px;
}

.mandatory-text p {
    font-size: 12px;
    color: $colorInverse;
    margin: 0;
}

.jbm-resume-form .input {
    width: 100%;
    margin-bottom: 45px;
    border: 0;
    border-bottom: 1px solid $colorFaded;
    padding-bottom: 10px;
    padding-top: 15px;
    font-size: 14px;
}

.check-mark {
    float: left;
    margin-top: 7px;
}

.job-posted .fa-trash {
    color: $colorFaded;
    cursor: pointer;
}

/* Payment History */
.table.table-striped tbody tr td {
    padding: 18px 20px;
}

.table-striped > tbody > tr:nth-of-type(2n + 1) {
    background-color: $colorFaded;
}

.table.table-striped thead tr td {
    color: #454545;
    font-size: 14px;
    font-weight: 700;
}

.input.margin-bottom-0 {
    margin-bottom: 0;
}

.selectstyle {
    color: #8f9ba7;
    height: 54px;
    border-radius: 0;
    box-shadow: none;
    font-weight: 500;
    border: 1px solid #a7b2bd !important;
    padding: 6px 20px;
}

.jbm-sch-inner.style2 select::-ms-expand {
    display: none;
}

.more-optionss {
    color: #8f9ba7;
    font-size: 12px;
    font-weight: 500;
}

#subscribe-btn {
    margin-top: 20px;
    padding-top: 0;
    padding-bottom: 0;
    width: auto;
    border-style: solid;
    border-width: 2px;
}

/* jbm-search-resulted */
.jbm-search-resulted {
    float: left;
    width: 100%;
    text-align: center;
}

.jbm-search-resulted h3 {
    color: $colorPrimary;
    font-size: 24px;
    font-weight: 900;
}

.jbm-search-resulted h3 span {
    color: $colorPrimary;
    font-size: 24px;
    font-weight: 400;
}

.padding-top-70 {
    padding-top: 70px;
}

.padding-bottom-70 {
    padding-bottom: 70px;
}

/* Terms & Conditions  */
.jbm-terms-conditions {
    float: left;
    width: 100%;
}

.jbm-terms {
    float: left;
    width: 100%;
}

.jbm-terms h4 {
    color: #454545;
    font-size: 18px;
    font-weight: 700;
}

.jbm-terms p {
    font-size: 14px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.jbm-terms ul {
    float: left;
    width: 100%;
    padding-left: 20px;
}

.jbm-terms li {
    float: left;
    width: 100%;
    padding-left: 20px;
}

.jbm-terms ol {
    float: left;
    width: 100%;
    padding-left: 20px;
}

/* FAQS PAGE */
.pd-leff {
    padding-left: 20px;
}

.pd-leff li {
    padding-left: 20px;
    padding-bottom: 5px;
}

.jbm-search-noresult {
    float: left;
    width: 100%;
    text-align: center;
}

.jbm-search-noresult p {
    color: $colorPrimary;
    font-size: 15px;
    font-weight: 400;
}

.jbm-search-noresult form {
    width: 340px;
    margin: 0 auto;
    position: relative;
}

.jbm-search-noresult form input {
    width: 340px;
    height: 60px;
    border: 1px solid #d4d4d4;
    color: $colorFaded;
    font-size: 14px;
    font-weight: 500;
    padding: 0 40px 0 20px;
}

.jbm-search-noresult form button {
    background: none;
    border: none;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
}

.jbm-search-noresult form i {
    color: $colorFaded;
    font-size: 14px;
}

.logoo {
    padding: 0 30px;
    text-align: center;
}

.jbm-logo.align-center {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
}

.jbm-logo.align-center img {
    display: inline-block;
}

.jbm-menu-container.style3 {
    float: left;
    width: 100%;
    text-align: center;
}

.jbm-menu-container.style3 .jbm-menu li:last-child {
    float: right;
}

.jbm-menu.style2 > li:nth-child(4) {
    text-align: center;
    position: relative;
    top: 12px;
    padding-right: 53px;
    padding-left: 87px;
}

.pop-reg {
    position: fixed;
    top: 0;
    left: 45%;
    transform: translateX(-50%);
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s ease-in-out;
}

.pop-reg.active {
    opacity: 1;
    visibility: visible;
}

.login-reg {
    position: fixed;
    top: 0;
    left: 45%;
    transform: translateX(-50%);
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s ease-in-out;
}

.login-reg.active {
    opacity: 1;
    visibility: visible;
}

.jbm-header-styles {
    float: left;
    width: 100%;
    background-color: #e3e3e3;
}

.jbm-header-styles header {
    margin-bottom: 100px;
    background: $colorLight;
}

.jbm-header-styles header:nth-child(3) {
    margin-bottom: 0;
}

.individual-plan {
    margin-bottom: 100px;
}

.accordian.margin-bottom-120 {
    margin-bottom: 120px;
}

.apply-job-popup .jbm-login-side ul {
    margin-bottom: 251px;
}

.terms.style3 small {
    color: #454545;
    font-size: 12px;
    margin-top: 2px;
}

.terms.style3 {
    margin: 0;
}

.terms.style3 input[type="checkbox"] + label span {
    margin-right: 10px;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #40505f;
    opacity: 0.7;
}

.apply-job-popup,
.jbm-login-popup,
.jbm-can-register-popup {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    z-index: 1;
    position: relative;
    display: none;
}

.apply-job-popup.active,
.jbm-login-popup.active,
.jbm-can-register-popup.active {
    visibility: visible;
    opacity: 1;
    z-index: 999;
    display: block;
}

/* Site Map */
.jbm-sitemap-section {
    float: left;
    width: 100%;
}

.jbm-sitemap-section ul {
    list-style: none;
    padding-left: 0px;
}

.jbm-sitemap > ul > li {
    position: relative;
    display: block;
}

.jbm-sitemap > ul > li > a:before {
    display: none;
}

.jbm-sitemap > ul > li > a:after {
    display: none;
}

.jbm-sitemap-section ul li a {
    color: #454545;
    font-size: 15px;
    font-weight: 400;
    width: 165px;
    text-align: center;
    background-color: #e3e3e3;
    padding: 15px 0;
    margin-bottom: 20px;
    display: inline-block;
    position: relative;
}

.jbm-sitemap-section ul li a:before {
    content: "";
    position: absolute;
    top: 19px;
    left: -5px;
    width: 12px;
    height: 12px;
    background-color: #86af49;
    border-radius: 50%;
}

.jbm-sitemap-section ul li a:after {
    content: "";
    position: absolute;
    top: 25px;
    right: 100%;
    width: 66px;
    height: 2px;
    background-color: #86af49;
}

.jbm-sitemap ul ul li a {
    width: 280px;
}

.jbm-sitemap-section ul ul {
    padding-left: 70px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: 60px;
}

.jbm-sitemap-section ul ul:before {
    content: "";
    position: absolute;
    top: 0;
    left: 3px;
    width: 2px;
    height: 100%;
    z-index: 9;
}

.jbm-sitemap-section ul ul:after {
    font-family: "Fontawesome";
    content: "\f111";
    position: absolute;
    top: 25px;
    left: -65px;
    width: 70px;
    height: 2px;
    line-height: 2px;
    text-indent: -5px;
}

.jbm-sitemap-section ul ul li {
    position: relative;
}

/* Spinner */
.jbm-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2c3240;
    z-index: 9999999;
}

.loader-circle {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
    margin-left: -60px;
    margin-top: -60px;
}

.loader-line-mask {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 120px;
    margin-left: -60px;
    margin-top: -60px;
    overflow: hidden;
    -webkit-transform-origin: 60px 60px;
    -moz-transform-origin: 60px 60px;
    -o-transform-origin: 60px 60px;
    -ms-transform-origin: 60px 60px;
    transform-origin: 60px 60px;
    -webkit-mask-image: -webkit-linear-gradient(top, $colorDark, rgba(0, 0, 0, 0));
    -webkit-animation: rotate 1.2s infinite linear;
    -moz-animation: rotate 1.2s infinite linear;
    -o-animation: rotate 1.2s infinite linear;
    animation: rotate 1.2s infinite linear;
}

.loader-line-mask .loader-line {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

lesshat-selector {
    -lh-property: 0;
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
    }
}

@-o-keyframes rotate {
    0% {
        -o-transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

lesshat-selector {
    -lh-property: 0;
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
    }
}

@-o-keyframes rotate {
    0% {
        -o-transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

lesshat-selector {
    -lh-property: 0;
}

@-webkit-keyframes fade {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.25;
    }
}

@-moz-keyframes fade {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.25;
    }
}

@-o-keyframes fade {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.25;
    }
}

@keyframes fade {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.25;
    }
}

lesshat-selector {
    -lh-property: 0;
}

@-webkit-keyframes fade {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.25;
    }
}

@-moz-keyframes fade {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.25;
    }
}

@-o-keyframes fade {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.25;
    }
}

@keyframes fade {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.25;
    }
}

lesshat-selector {
    -lh-property: 0;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

lesshat-selector {
    -lh-property: 0;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.subsribe-email {
    position: relative;
}

.subsribe-email .form-control {
    padding-left: 12px;
}

.subs-email-label {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-bottom: 0px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    left: auto !important;
}





.jbm-social-icon-1 {
    display: inline-block;

    li {
        display: inline-block;
        margin-right: 10px;

        &:last-child {
            margin-right: 0px;
        }

        a {
            color: $colorInverse;

            &:focus {
                color: $colorLight;
            }
        }
    }
}

.jbm-social-icon-1.jbm-effect-2 li a:hover {
    position: relative;
}
