// Page specific styles
section {
    &.page {
        margin: 0;
        padding: 0;
        // About Us
        &.about {
            .who-we-are {
                .section-title {
                    margin-bottom: 30px;
                    h3 {
                        color: $colorPrimary;
                    }
                }
                .who-we-are-content {
                    overflow: unset;
                    display: block;
                    -webkit-line-clamp: unset;
                    -webkit-box-orient: unset;
                    p {
                        font-size: 14px;
                        color: lighten($colorInverse, 20%);
                    }
                }
            }
            .contact-us {
                float: left;
                width: 100%;
                position: relative;
                padding-top: 0px;
                padding-bottom: 0px;
                .contact-info {
                    float: left;
                    width: 100%;
                    padding-top: 0;
                    h4 {
                        padding-bottom: 15px;
                        font-weight: 700;
                    }
                    .section-title-line {
                        position: relative;
                        width: 100%;
                        height: 1px;
                        background-color: $colorFaded;
                        display: block;
                        &:after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0px;
                            width: 50px;
                            height: 1px;
                            background-color: $colorPrimary;
                        }
                    }
                    .contact-form,
                    .contact-dt {
                        padding: 50px 0;
                    }
                    .contact-dt {
                        &:hover {
                            ul {
                                -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
                                -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
                                -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
                                -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
                                box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
                            }
                        }
                        ul {
                            float: left;
                            width: 100%;
                            border: 1px solid $colorFaded;
                            padding: 18px 20px;
                            li {
                                float: left;
                                width: 100%;
                                list-style: none;
                                margin-bottom: 38px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                                i {
                                    font-size: 20px;
                                    width: 55px;
                                    height: 51px;
                                    line-height: 51px;
                                    border-radius: 100px;
                                    text-align: center;
                                    display: table-cell;
                                    background-color: transparent;
                                    color: $colorPrimary;
                                    border: 2px solid $colorPrimary;
                                }
                                h4 {
                                    font-family: "Libre Franklin", sans-serif;
                                    color: lighten($colorInverse, 20%);
                                    font-size: 16px;
                                    font-weight: 600;
                                    display: table-cell;
                                    vertical-align: middle;
                                    padding: 3px 0 0 20px;
                                    a {
                                        font-size: inherit;
                                        color: inherit;
                                        &:hover, &:focus {
                                            color: $colorPrimary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .contact-form {
                        float: left;
                        width: 100%;
                        form {
                            border: 1px solid $colorFaded;
                            padding: 20px 20px 20px;
                            transition: all 0.4s ease-in-out;
                        }
                        &:hover {
                            form {
                                -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
                                -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
                                -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
                                -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
                                box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
                            }
                        }
                    }
                }
                .contact-map {
                    float: left;
                    width: 100%;
                    height: 454px;
                    margin-top: 0px;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
                    #map_canvas {
                        width: 100%;
                        height: auto;
                        border-top: 4px solid $colorPrimary;
                    }
                }
                @media (max-width: 991px) {
                    .contact-map {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}
