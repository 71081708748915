$cardBorderRadius: 10px;
.card {
    padding: 0;
    background-color: $colorInverse;
    border: 0;
    box-shadow: 0px 0px 8px -3px $colorInverse;
    border-radius: $cardBorderRadius;
    img {
        border-top-left-radius: $cardBorderRadius;
        border-top-right-radius: $cardBorderRadius;
        width: 100%;
        height: auto;
    }
    .sticker {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 60px;
        background-color: $colorInverse;
        color: $colorLight;
        text-align: center;
        .body {
            font-size: 16px;
            display: block;
            line-height: 20px;
            padding: 12px 8px;
            font-weight: 900;
        }
        .foot {
            width: 100%;
            display: block;
            font-size: 10px;
            padding: 4px;
            overflow: hidden;
            color: $colorInverse;
            background-color: $colorPrimary;
        }
    }
    .card-meta {
        background-color: darken($colorFaded, 2%);
        // background-color: $colorFaded;
        padding: 5px 10px;
        span {
            font-size: 14px;
        }
        ul {
            list-style: none;
            right: 0;
            float: right;
            margin-bottom: 0;
            li {
                float: left;
                margin-right: 5px;
            }
        }
    }
    .caption {
        color: $colorLight;
        display: block;
        &:before {
            display: block;
            clear: both;
            content: "";
            position: relative;
            left: 0%;
            bottom: 11px;
            max-width: 20%;
            border-top: 2px solid $colorPrimary;
        }
        .card-title {
            color: $colorLight;
            overflow: hidden;
            &:hover,
            &:focus {
                color: $colorPrimary;
            }
            a {
                color: $colorLight;
                text-decoration: none;
                &:hover,
                &:focus {
                    color: $colorPrimary;
                }
            }
        }
        .card-excerpt {
            width: 250px;
            height: 35px;
            font-size: 12px;
            line-height: 18px;
            font-weight: 100;
            white-space: pre-wrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .card-body {
            height: 50px;
            overflow: hidden;
        }
        .card-footer {
            padding-top: 25px !important;
            .card-link {
                color: $colorLight;
                font-weight: 900;
                &:hover,
                &:focus {
                    color: $colorPrimary;
                }
            }
        }
    }
    &.card-light {
        background-color: $colorLight;
        .caption {
            color: $colorInverse;
            &:before {
                border-top: 2px solid $colorInverse;
            }
            .card-title {
                color: $colorInverse;
                &:hover,
                &:focus {
                    color: $colorPrimary;
                }
                a {
                    color: $colorInverse;
                    &:hover,
                    &:focus {
                        color: $colorPrimary;
                    }
                }
            }
        }
    }
}