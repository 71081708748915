$colorPrimary: #16bac5;
$colorSecondary: #e5c228;
$colorTertiary: #5cb85c;

$colorSuccess: #5cb85c;
$colorInfo: #5bc0de;
$colorWarning: #f0ad4e;
$colorDanger: #d9534f;

$colorInverse: #292b2c;
$colorFaded: #f5f5f5;
$colorDark: #000000;
$colorLight: #ffffff;