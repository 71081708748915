/*width*/
.width-100-percent {
    width: 100%;
}

.uppercase {
    text-transform: uppercase;
}

.center {
    display: inline-block;
    position: relative;
    left: 40%;
}
.vertical-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

p {
    line-height: 26px;
    font-size: 14px;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

/* Margin */
.margin-auto {
    margin: 0 auto;
    display: block;
}

.margin-top-0 {
    margin-top: 0px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-90 {
    margin-top: 90px;
}

.margin-top-100 {
    margin-top: 100px;
}

.margin-top-85-minus {
    margin-top: -85px;
}

.margin-bottom-0 {
    margin-bottom: 0px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-45 {
    margin-bottom: 45px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-65 {
    margin-bottom: 65px;
}

.margin-bottom-55 {
    margin-bottom: 55px;
}

.margin-bottom-70 {
    margin-bottom: 70px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-90 {
    margin-bottom: 90px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}

.margin-bottom-150 {
    margin-bottom: 150px;
}

.margin-right-30 {
    margin-right: 30px;
}

.margin-right-70 {
    margin-right: 70px;
}

/* Padding */
.padding-top-15 {
    padding-top: 15px;
}

.padding-top-30 {
    padding-top: 30px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-75 {
    padding-top: 75px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-90 {
    padding-top: 90px;
}

.padding-top-100 {
    padding-top: 100px;
}

.padding-top-115 {
    padding-top: 115px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-70 {
    padding-bottom: 70px;
}

.padding-bottom-75 {
    padding-bottom: 75px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-85 {
    padding-bottom: 85px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}

.padding-bottom-150 {
    padding-bottom: 150px !important;
}

.padding-bottom-170 {
    padding-bottom: 170px;
}

.trim-lines-1 {
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; // number of lines to show :  N
    line-height: 1.5em; // fallback  : X
    max-height: 1.5em * 1; // fallback  : X*N
}
.trim-lines-2 {
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; // number of lines to show :  N
    line-height: 1.5em; // fallback  : X
    max-height: 1.5em * 2; // fallback  : X*N
}
.trim-lines-3 {
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; // number of lines to show :  N
    line-height: 1.5em; // fallback  : X
    max-height: 1.5em * 3; // fallback  : X*N
}
.trim-lines-5 {
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; // number of lines to show :  N
    line-height: 1.5em; // fallback  : X
    max-height: 1.5em * 5; // fallback  : X*N
}

.hide-scroll-bar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
