// Typography
h1,
h1 a {
    font-size: 48px;
}

h2,
h2 a {
    font-size: 40px;
}

h3,
h3 a {
    font-size: 32px;
}

h4,
h4 a {
    font-size: 24px;
}

h5,
h5 a {
    font-size: 18px;
}

h6,
h6 a {
    font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    font-family: "Raleway", sans-serif;
}

// Font Weight
.fw-100 {
    font-weight: 100;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}