.slider {
    .hero-slider {
        background-color: lighten($colorInverse, 5%);
        position: relative;
        width: 100%;
        .single-hs-item {
            .content {
                bottom: 50px;
                color: $colorLight;
                position: absolute;
                text-align: center;
                width: 100%;
                .title {
                    color: $colorPrimary;
                    font-size: 16px;
                    font-weight: 900;
                    text-transform: uppercase;
                }
                .description {
                    font-size: 30px;
                    a {
                        color: inherit;
                        text-decoration: none;
                    }
                }
            }
        }
        .owl-item.active {
            .title,
            .description,
            .ktm-button {
                -webkit-animation: 1s 0.3s fadeInUp both;
                animation: 1s 0.3s fadeInUp both;
            }
        }
    }
    .owl-theme {
        .owl-dots {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            .owl-dot {
                &.active,
                &:hover,
                &:focus {
                    span {
                        background-color: $colorPrimary;
                    }
                }
                span {
                    width: 45px;
                    height: 3px;
                    background-color: $colorLight;
                }
            }
        }
    }
}
@media only screen and (min-width: 1024px) {
    .slider {
        .hero-slider {
            .single-hs-item {
                position: relative;
                overflow: hidden;
                img {
                    display: block;
                    width: 100vw;
                    height: 50vh;
                    object-fit: cover; /* or object-fit: contain; */
                }
                .content {
                    .title {
                        font-size: 20px;
                    }
                    .description {
                        font-size: 40px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .slider {
        .hero-slider {
            .single-hs-item {
                .content {
                    .title {
                        font-size: 15px;
                    }
                    .description {
                        font-size: 30px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 425px) {
    .slider {
        .hero-slider {
            .single-hs-item {
                .content {
                    bottom: 30px;
                    .title {
                        font-size: 15px;
                        margin-bottom: 0;
                    }
                    .description {
                        font-size: 20px;
                    }
                }
            }
        }
        .owl-theme {
            .owl-dots {
                bottom: -10px;
            }
        }
    }
}
@media only screen and (max-width: 375px) {
    .slider {
        .hero-slider {
            .single-hs-item {
                .content {
                    bottom: 30px;
                    .title {
                        font-size: 15px;
                        margin-bottom: 0;
                    }
                    .description {
                        font-size: 20px;
                        margin-bottom: 0;
                    }
                }
            }
        }
        .owl-theme {
            .owl-dots {
                bottom: -10px;
            }
        }
    }
}
