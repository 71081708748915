/* Page Title */
.page-title {
    width: 100%;
    padding-top: 75px;
    padding-bottom: 75px;
    position: relative;
    background-size: cover;
    background-image: no-repeat;
    background-position: center center;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: lighten($colorInverse, 2%);
        z-index: 8;
    }
    &.smt {
        h2 {
            margin-bottom: 45px;
        }
        p {
            padding-bottom: 50px;
        }
    }
    h2 {
        margin-bottom: 65px;
        color: $colorPrimary;
        margin-top: 10px;
        font-size: 32px;
        font-weight: 600;
    }
    p {
        color: $colorLight;
        margin-bottom: 0;
        font-size: 15px;
        a {
            color: $colorLight;
            margin-bottom: 0;
            font-size: 15px;
            i {
                font-size: 18px;
                color: $colorPrimary;
            }
        }
    }
    .container {
        position: relative;
        z-index: 9;
    }
    .section-title-line {
        background-color: $colorPrimary;
    }
}
@media (max-width: 768px) {
    .page-title {
        h2 {
            margin-bottom: 20px;
            font-size: 20px;
        }
        p {
            font-size: 15px;
            a {
                font-size: 14px;
                i {
                    font-size: 16px;
                }
            }
        }
    }
}