input[type="email"]:focus,
button:hover,
button:focus {
    // box-shadow: none;
    // outline: 0;
}
.form-inline .form-group {
    // width: inherit;
}
.form-group {
    // position: relative;
    // width: 100%;
    .form-control {
        // border-color: #a7b2bd;
        // color: $colorFaded;
        &:focus {
            border-color: $colorPrimary;
            -webkit-box-shadow: 0px 0px 10px 0px $colorPrimary; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
            -moz-box-shadow: 0px 0px 10px 0px $colorPrimary; /* Firefox 3.5 - 3.6 */
            box-shadow: 0px 0px 10px 0px $colorPrimary;
        }
    }

    // label {
    //     position: absolute;
    //     margin-bottom: 10px;
    //     bottom: 6px;
    //     left: 0;
    //     font-size: 16px;
    //     font-weight: 400;
    //     color: $colorPrimary;
    //     -webkit-transform-origin: bottom left;
    //     transform-origin: bottom left;
    //     -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    //     transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    //     -webkit-transition-property: color, bottom, transform;
    //     transition-property: color, bottom, transform;
    // }
    // input,
    // textarea {
    //     width: 100%;
    //     background-color: $colorLight;
    //     color: $colorInverse;
    //     font-size: 14px;
    //     font-weight: 400;
    //     border: none;
    //     border-bottom: 1px solid $colorFaded;
    //     outline: none;
    //     margin-bottom: 40px;
    // }
    // input {
    //     padding-bottom: 15px;
    //     padding-top: 15px;
    //     font-size: 14px;
    //     margin: 0 !important;
    //     &:focus {
    //         border-color: $colorPrimary;
    //         & ~ label {
    //             bottom: 35px;
    //             font-size: 12px;
    //             font-weight: 600;
    //             color: $colorPrimary;
    //         }
    //     }
    // }
    // textarea {
    //     &:focus {
    //         & ~ label {
    //             top: -20px !important;
    //             bottom: auto;
    //             font-size: 12px;
    //             font-weight: 600;
    //             color: $colorPrimary;
    //         }
    //     }
    //     & ~ label {
    //         top: 0px !important;
    //         transition: all 0.3s ease;
    //         -webkit-transition: all 0.3s ease;
    //         -moz-transition: all 0.3s ease;
    //         -o-transition: all 0.3s ease;
    //     }
    // }
    // &.field-active {
    //     input,
    //     textarea {
    //         border-color: $colorPrimary;
    //         & ~ label {
    //             font-size: 16px;
    //             font-weight: 600;
    //             color: $colorPrimary;
    //         }
    //     }
    //     input {
    //         & ~ label {
    //             bottom: 35px;
    //         }
    //     }
    //     textarea {
    //         & ~ label {
    //             top: -20px !important;
    //             bottom: auto;
    //         }
    //     }
    // }
    // &.fa {
    //     &:before {
    //         position: absolute;
    //         bottom: 14px;
    //         right: 0;
    //         color: $colorLight;
    //     }
    // }
}
