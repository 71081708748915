// Footer
footer {
    float: left;
    width: 100%;
    background-color: lighten($colorInverse, 5%);
    color: $colorFaded;
    font-size: 0.9rem;
    // Footer Wiget
    .widget {
        &.widget-text {
            p {
                // font-size: 0.8rem;
            }
        }
        .widget-links {
            padding: 0px;
            margin: 0px;
            list-style: none;
            color: $colorFaded;
            li {
                display: block;
                margin-bottom: 0.2rem;
                &:last-child {
                    margin-bottom: 0px;
                }
                i {
                    margin-right: 10px;
                    color: $colorLight;
                }
                a {
                    text-decoration: none;
                    // font-size: 0.8rem;
                    color: $colorFaded;
                    transition: all 0.4s ease-in-out;
                }
                &:hover,
                &:focus {
                    i {
                        color: $colorSecondary;
                    }
                    a {
                        color: $colorLight;
                    }
                }
            }
        }
    }
    // Footer Bottom Bar
    .footer-bottom-bar {
        background-color: $colorInverse;
        color: $colorFaded;
        padding-top: 31px;
        padding-bottom: 31px;
        // scroll to top button
        .scroll-to-top {
            position: relative;
            text-align: center;
            a {
                &.scroll-to-top-button {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    line-height: 40px;
                    font-size: 14px;
                    display: inline-block;
                    position: absolute;
                    top: -50px;
                    left: 50%;
                    background-color: $colorSecondary;
                    transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    &:hover,
                    &:focus {
                        color: $colorInverse;
                        background-color: $colorFaded;
                    }
                    i {
                        color: $colorInverse;
                    }
                }
                &.scroll-to-top-link {
                    color: $colorFaded;
                    &:hover,
                    &:focus {
                        color: $colorPrimary;
                    }
                }
            }
        }
        p {
            margin-bottom: 0px;
            font-size: 12px;

            i {
                text-decoration: none;
            }
            &.ktm-footer-credit {
                text-align: center;
                a {
                    text-decoration: none;
                    color: $colorLight;
                }
            }
        }
    }
}
