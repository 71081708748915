// Floating Action button
.fab-container {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 999;
    cursor: pointer;
    .fab {
        width: 60px;
        height: 60px;
        &.fab-icon-holder {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background: $colorInverse;
            box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
            &:hover {
                opacity: 0.8;
            }
            i {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                font-size: 25px;
                color: $colorLight;
            }
        }
    }
    .fab-options {
        list-style-type: none;
        margin: 0;
        position: absolute;
        bottom: 70px;
        right: 0;
        opacity: 0;
        transition: all 0.3s ease;
        transform: scale(0);
        transform-origin: 85% bottom;
        li {
            display: flex;
            justify-content: flex-end;
            padding: 5px;
            .fab-label {
                padding: 2px 5px;
                align-self: center;
                user-select: none;
                white-space: nowrap;
                border-radius: 3px;
                font-size: 16px;
                background: $colorLight;
                box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
                margin-right: 10px;
                a {
                    color: $colorInverse;
                }
            }
        }
    }
    .fab:focus,
    .fab:hover + .fab-options,
    .fab-options:hover {
        opacity: 1;
        transform: scale(1);
    }
}
@media only screen and (max-width: 768px) {
    .fab-container {
        position: fixed;
        bottom: 15px;
        right: 15px;
    }
}
