// Layout
html,
body {
    width: auto !important;
    overflow-x: hidden !important;
}
body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 13px;
    background-color: $colorLight;
    padding-top: 50px;
}