section {
    width: 100%;
    height: auto;
    padding: 50px 0;
    background-color: $colorLight;
    .section-title {
        text-align: center;
        .section-title-line {
            width: 70px;
            height: 20px;
            height: 3px;
            display: inline-block;
            background-color: $colorPrimary;
        }
        h2 {
            font-weight: 400;
            color: $colorPrimary;
            span {
                color: $colorDark;
                font-weight: 300;
            }
        }
        p {
            font-size: 16px;
            color: $colorInverse;
        }
    }
    .section-content {
        img {
            width: 100%;
            height: auto;
        }
    }
    &.section-primary {
        background-color: $colorPrimary;
        .section-title {
            .section-title-line {
                background-color: $colorLight;
            }
            h2 {
                color: $colorLight;
                span {
                    color: $colorLight;
                }
            }
            p {
                color: $colorLight;
            }
        }
    }
    &.section-faded {
        background-color: $colorFaded;
        .section-title {
            .section-title-line {
                background-color: $colorPrimary;
            }
            h2 {
                color: $colorPrimary;
                span {
                    color: $colorDark;
                }
            }
            p {
                color: $colorDark;
            }
        }
    }
    &.section-dark {
        background-color: $colorDark;
        .section-title {
            .section-title-line {
                background-color: $colorPrimary;
            }
            h2 {
                color: $colorSecondary;
                span{
                    color: $colorLight;
                }
            }
            p {
                color: $colorLight;
            }
        }
    }
}
