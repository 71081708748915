// Import Custom Components
@import "./components/colors";
@import "./components/section";
@import "./components/glassmorphism";
@import "./components/card";
@import "./components/layout";
@import "./components/elements";
@import "./components/form";
@import "./components/footer";
@import "./components/fab";
@import "./components/section";
@import "./components/page";
@import "./components/card";
@import "./components/slider";
@import "./components/utility";
@import "./components/typography";
@import "./components/responsive";
@import "./page";
@import "./old";

// error_page
.error-page {
    margin-top: 30px;
    margin-bottom: 50px;
    text-align: center;
    h1 {
        font-size: 20vw;
        font-weight: 600;
        color: $colorPrimary;
    }
}
// blog
.blog-date {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 60px;
    background-color: $colorInverse;
    color: $colorLight;
    text-align: center;
    .date-sp {
        font-size: 16px;
        display: block;
        line-height: 20px;
        padding: 12px 8px;
        font-weight: 900;
    }
    .blog-cat {
        width: 100%;
        display: block;
        font-size: 10px;
        padding: 4px;
        overflow: hidden;
        color: $colorInverse;
        background-color: $colorPrimary;
    }
}
// Sidebar
.sidebar {
    .sidebar-outer {
        border: 1px solid $colorFaded;
        padding: 4px;
        ul {
            list-style: none;
            padding-left: 0;
            li {
                line-height: 26px;
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
                span {
                    float: right;
                }
                a {
                    color: $colorInverse;
                    font-size: 14px;
                    &:hover {
                        text-decoration: none;
                    }
                    &.active {
                        color: $colorPrimary;
                    }
                }
            }
        }
        .sidebar-inner {
            border: 1px solid $colorFaded;
            overflow: hidden;
            padding-right: 25px;
            padding-left: 25px;

            .sidebar-title {
                font-size: 18px;
                font-weight: 600;
                text-transform: uppercase;
                color: $colorPrimary;
            }

            .section-tit-line {
                width: 30px;
                height: 2px;
            }
        }
    }
}
// pagination
.pagination {
    border-top: 1px solid $colorFaded;
    border-bottom: 1px solid $colorFaded;
    padding-top: 22px;
    padding-bottom: 20px;
    ul {
        list-style: none !important;
        margin: auto;
        overflow: hidden !important;
        width: 41%;
        li {
            float: left !important;
            margin-right: 15px;
            a {
                width: 35px;
                border-radius: 50%;
                height: 35px;
                line-height: 35px;
                background: $colorFaded;
                cursor: pointer;
                color: $colorDark;
                text-decoration: none;
                display: inline-block;
                &.active,
                &:hover,
                &:focus {
                    color: $colorLight;
                    background-color: $colorPrimary;
                    outline: none;
                }
            }
        }
    }
    .fa {
        height: 35px;
        width: 35px;
        line-height: 35px;
        border-radius: 40px;
        text-align: center;
        position: relative;
        bottom: 34px;
        background: $colorFaded;
        &:hover {
            color: $colorLight;
            background: $colorPrimary;
        }
    }
}